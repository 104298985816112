import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import './homepage.scss';

const bem = new BEMHelper({
  name: 'content-homepage-heading',
});

export const HomepageHeading = () => {
  const { t } = useTranslation();

  return (
    <Content {...bem()} observe useOwnSpacing>
      <h1 {...bem('title')}>
        <span {...bem('opening')}>{t('Welcome to')}</span>
        <span {...bem('name')}>
          Med
          <span {...bem('highlight')}>Skin</span>
        </span>
        <span {...bem('name')}>Solutions</span>
        <span {...bem('byline')}>Dr. Suwelack</span>
      </h1>
      <p {...bem('scroll-indicator')}>
        <small>
          <a href="#contents" title={t('Click here to scroll to the content section')} {...bem('link')}><span {...bem('text')}>{t('Scroll for more info')}</span></a>
        </small>
      </p>
    </Content>
  );
};
