import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, shape, string } from 'prop-types';

import { Renderer } from '../../components/common/renderer';
import { CONTENT_MAPPING } from '../../components/content/mapping';
import { Sitemeta } from '../../components/core/sitemeta';
import { HomepageHeading } from '../../components/headings/homepage';
import { NewsTeaser } from '../../components/content/teaser/news';
import { globalSetType, responseType } from '../../types';

export const query = graphql`
  query HomePage {
    response: craftgql {
      home: globalSet(handle: "homepage") {
        ...on CraftGQL_homepage_GlobalSet {
          ...HomeContentsFragment

          metadata: pageMetadata {
            ...MetadataFragment
          }
        }
      }
    }
  }
`;

const HomePage = ({ data }) => {
  const { t } = useTranslation();
  const { contents, metadata: [metadata] } = data.response.home;

  return (
    <>
      <Sitemeta title={t('Homepage')} {...metadata} />
      <HomepageHeading />
      <div id="contents">
        <NewsTeaser limit={3} />
        <Renderer contents={contents} mapping={CONTENT_MAPPING} />
      </div>
    </>
  );
};

HomePage.propTypes = responseType({
  home: globalSetType({
    contents: arrayOf(shape({
      typename: string,
    })).isRequired,
  }).isRequired,
}).isRequired;

export default HomePage;
