// news.jsx - Represents a single news archive entry
import { useStaticQuery, graphql, Link } from 'gatsby';
import { string, array, number } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Content } from '../../../common/content';
import { getLinkProps } from '../utils';
import './news.scss';

import dateformat from "dateformat"

const bem = new BEMHelper({
  name: 'content-newsteaser',
});

const query = graphql`
  query {
    response: craftgql {
      news: entries(
        section: "news",
        newsOnFrontpage: true,
      ) {
        ...on CraftGQL_news_news_Entry {
          __typename
          title
          slug: pageSlug
          publishedDate
          pageExcerpt
          departments: pageDepartments {
            slug
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }
      }
    }
  }
`;

export const NewsTeaser = ({
                          id
                        }) => {
  const { t } = useTranslation();
  const { response } = useStaticQuery(query);
  const {
    news,
  } = response;


  // FIXME: Clone to element subarray for getLinkProps()
  news.map( function(entry) {
    entry['element'] = [];
    entry['element']['slug'] = entry.slug;
    entry['element']['__typename'] = entry.__typename;
  });

  return (
    <div { ...bem('section') }>
      <h3>{ t('Latest news') }</h3>
      <div { ...bem('wrap') }>
      {news.map((entry) => (
          <Content key={entry.slug} id={id} {...bem('entry')} useOwnSpacing>
            <div {...bem('textcontainer')}>
              <h2 {...bem('headline')}>
                <Link
                  {...bem('link')}
                  {...getLinkProps(entry)}>
                  {entry.title}
                </Link>
              </h2>
              <div {...bem('date')}>{ dateformat(entry.publishedDate, `fullDate`) }</div>
              <div {...bem('excerpt')}>{ parse(entry.pageExcerpt) }</div>
              <div>
                <Link
                  {...bem('button', '', 'button button--secondary')}
                  {...getLinkProps(entry)}>
                  { t('Read more') }
                </Link>
              </div>
            </div>
          </Content>
      ))}
      </div>
    </div>
  );
};

NewsTeaser.propTypes = {
  limit: number.isRequired,
};
